<template>

    <!-- 引入内容组件 -->
    <div id="homebox">
      <div id="pagebox">
        <div class="flexcent">您没有权限访问{{title}}， 请 <span @click="tologin">登录</span> 后再试</div>
      </div>
    </div>

</template>

<script>


export default {
  data() {
    return {
      title:"",
      redirect: '',
      primary: 0, //一级菜单的选中样式
      istrue: true, //刷新页面
      path:this.$base.baseUrl,//
    };
  },
  components: {

  },
  created() {
     this.title = this.$route.query.title
     this.redirect = this.$route.query.redirect
  },
  mounted() {},
  methods: {
    // 点击跳到登录页面
    tologin(){
      this.until.jump.call(this, "/login", "push",{redirect: this.redirect});
    }
  },
};
</script>

<style scoped lang="less">

  #homebox{
    min-height: 50vh;
    .flexcent{
      margin-top: 3.125rem;
      align-items: flex-end;
      span{
            font-size: 1.5rem;
            margin: 0 .625rem;
    color: #0000ff;
    cursor: pointer;
      }
      span:hover{
        color: #266bff;
      }
      span:active{
        opacity: .7;
      }
    }
  }
// @media screen and (min-width: 1000px) {}

// @media screen and (max-width: 999px) {
// }
</style>
